@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --clrPrimaryMain: #b6b2cc;
  --clrPrimaryDark: #fff;
  --clrPrimaryLight: #635e76;
  --clrNavbarText: #8579b4;
  --clrNavbarBg: #130c3e;
  --bgPrimaryMain: #14112c;
  --bgPrimaryDark: #080421;
  --bgPrimaryLight: #24223b;
  --bgPrimaryLightAsh: #2f2c4e;
}

::-webkit-scrollbar {
  width: 0.5rem;
  background: var(--bgPrimaryMain);
}

::-webkit-scrollbar-thumb {
  background: var(--clrNavbarText);
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #8579b4aa;
}

*,
*::before,
*::after {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  border: none;
  list-style: none;
  text-decoration: none;
}

html {
  font-size: 15px;
}

body {
  font-family: "Poppins", sans-serif;
  color: var(--clrPrimaryMain);
  background: #080421;
}

.dashboard {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 4fr;
}

.navbar,
.outlet {
  height: 100%;
  overflow: auto;
  padding: 1rem;
}

.navbar-container .logo {
  width: fit-content;
  margin: 1rem 0rem 2rem 0rem;
}

.navbar-container {
  margin-left: 1rem;
}

.navbar-container .navbar-link {
  color: var(--clrNavbarText);
  margin-top: 0.25rem;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.active {
  color: var(--clrPrimaryDark);
  background: var(--clrNavbarBg);
}

.navbar-link:hover {
  background: var(--clrNavbarBg);
}

.outlet-container {
  background: #14112c;
  border-radius: 0.5rem;
}

.youtube-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem 0rem 2rem;
}

.youtube-header-title {
  font-weight: 700;
  font-size: 34px;
  background: linear-gradient(277.65deg, #e0d8ff 28.22%, #9d8cfe 92.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.youtube-header-filter-wrapper {
  position: relative;
  z-index: 9;
}

.youtube-header-filter {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.75rem;
  border-radius: 0.5rem;
  background: var(--bgPrimaryLight);
  transition: 100ms all ease;
  z-index: 9;
}

.youtube-header-filter:active {
  transform: scale(95%);
}

.youtube-header-filter span {
  color: #b6b2cc;
  font-weight: 400;
  font-size: 14px;
}

/* DateRange CSS Started */
.date-range-filter {
  position: absolute;
  top: 150%;
  right: 0;
  opacity: 0;
  transition: 250ms all ease-in-out;
  pointer-events: none;
}

.date-range-filter.show {
  opacity: 1;
  pointer-events: all;
}
.rdrDateRangePickerWrapper * {
  background-color: var(--bgPrimaryLightAsh) !important;
  color: white !important;
}
.rdrDateRangePickerWrapper {
  border: 1px solid var(--clrPrimaryLight);
  border-radius: 1rem;
  overflow: hidden;
}
div.rdrDateRangePickerWrapper.date-range-filter.show
  > div.rdrCalendarWrapper.rdrDateRangeWrapper
  > div.rdrDateDisplayWrapper
  > div
  > span
  > input {
  border-radius: 5px !important;
}
.rdrDateDisplayItem {
  border: none !important;
}
.rdrDateDisplayItem > input {
  background-color: var(--bgPrimaryLight) !important;
}
.rdrDayPassive * {
  color: var(--clrPrimaryLight) !important;
}
.rdrStaticRange {
  background-color: var(--bgPrimaryLight) !important;
  border: none !important;
  padding: 0.6rem 0.8rem !important;
}
.rdrStaticRangeLabel {
  margin: 0 !important;
  border-radius: 0.5rem !important;
}
.rdrStaticRangeLabel:hover,
.rdrDateDisplayItem > input:hover,
.rdrMonthPicker > select:hover,
.rdrYearPicker > select:hover,
.rdrNextPrevButton:hover {
  background-color: var(--bgPrimaryMain) !important;
}
.rdrMonthAndYearWrapper * {
  background-color: var(--bgPrimaryLight) !important;
}

/* DateRange CSS ended */
.youtube-content {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  padding: 2rem;
}

.youtube-content-card-1,
.youtube-content-card-2,
.youtube-content-card-3,
.youtube-content-card-4,
.youtube-content-card-5,
.youtube-content-card-6,
.youtube-content-card-7,
.youtube-content-card-8 {
  background: var(--bgPrimaryLight);
  padding: 1rem;
  border-radius: 0.5rem;
}

.youtube-content-card-1,
.youtube-content-card-2,
.youtube-content-card-3 {
  grid-column: span 1;
}

.youtube-content-card-4 {
  grid-column: 4;
  grid-row: 1 / 4;
}

.youtube-content-card-5 {
  grid-column: span 2;
}

.youtube-content-card-6 {
  grid-column: span 1;
}

.youtube-content-card-7 {
  grid-column: span 2;
}

.youtube-content-card-8 {
  grid-column: span 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid var(--bgPrimaryLight);
}

.header-title {
  color: var(--clrPrimaryDark);
  font-weight: 700;
  font-size: 18px;
}

.header-title span {
  color: #9583fe;
}

.header-actions {
  display: flex;
  align-items: center;
}

.header-search {
  color: var(--clrPrimaryDark);
  background: rgba(255, 255, 255, 0.07);
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  width: 20rem;
}

.header-search::placeholder {
  color: #635e76;
  font-weight: 400;
  font-size: 14px;
}

.header-notification {
  background: rgba(255, 255, 255, 0.07);
  padding: 0.25rem;
  margin-left: 0.5rem;
  border-radius: 0.5rem;
  display: grid;
  place-items: center;
  cursor: pointer;
  transition: 100ms all ease-in-out;
}

.header-notification:active {
  transform: scale(90%);
}

.card-header {
  color: var(--clrPrimaryMain);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
}

.card-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-action-button {
  color: #8579b4;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #2a2747;
  transition: 100ms all ease-in-out;
  user-select: none;
}

.card-action-button:active {
  transform: scale(90%);
}

.card-body {
  color: var(--clrPrimaryDark);
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
}
.card-data-percentage {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}

.card-content {
  color: var(--clrPrimaryLight);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 32px;
}

.keyword {
  color: #33304f;
  margin: 0.5rem 0rem;
}

.keyword-index {
  color: #8579b4;
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
  min-width: 20px;
}

.keyword-btn {
  color: #b6b2cc;
  font-weight: 500;
  font-size: 12px;
  background: linear-gradient(
      97.91deg,
      rgba(149, 131, 254, 0.2) 6.1%,
      rgba(254, 130, 219, 0.2) 103.66%
    ),
    rgba(29, 29, 29, 0.05);
  box-shadow: inset 0px 0.5px 2px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(1.5px);
  border-radius: 5px;
  padding: 0.5rem 1rem;
  margin-left: 0.5rem;
}

.youtube-video-wrapper {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  margin-top: 1rem;
}

.youtube-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.youtube-video-meta {
  color: var(--clrPrimaryDark);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-top: 0.5rem;
}

.youtube-video-meta > span {
  color: var(--clrPrimaryLight);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.youtube-video-description {
  color: var(--clrPrimaryLight);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
}

.thumbnail {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-top: 1px solid #33304f;
  padding: 1rem 0rem;
}

.thumbnail-img {
  width: 100%;
  border-radius: 0.5rem;
}

.thumbnail-title {
  color: var(--clrPrimaryDark);
  font-weight: 600;
  font-size: 16px;
  max-width: 125px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.thumbnail-views {
  color: #635e76;
  font-weight: 500;
  font-size: 12px;
}

.highcharts-credits {
  display: none;
}

.login-container {
  min-height: 100vh;
  display: grid;
  place-items: center;
  padding: 1rem;
}

.login-card {
  max-width: 350px;
  background: var(--bgPrimaryMain);
  border-radius: 0.5rem;
  padding: 2rem;
}

.logo-container {
  text-align: center;
  margin-bottom: 1rem;
}

.login-title {
  text-align: center;
  margin-bottom: 1rem;
}

.login-title span {
  text-decoration: underline;
  line-height: 2.5rem;
}

.login-btn {
  width: 100%;
  color: var(--clrPrimaryMain);
  background: var(--bgPrimaryLight);
  font-weight: 500;
  font-size: 18px;
  padding: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-btn:hover {
  background: #2b2948;
}

/* SEO Starts */

.seo {
  padding: 2rem;
}

/* SEO Ends */

/* MusicEnginer Starts */

.music-engine {
  padding: 2rem;
}

/* MusicEnginer Ends */

/* Payments Starts */

.payments {
  padding: 2rem;
}

/* Payments Ends */

/* Settings Starts */

.settings {
  padding: 2rem;
}

/* Settings Ends */

/* Profile Starts */

.profile {
  padding: 2rem;
}

/* Profile Ends */

@media screen and (max-width: 1024px) {
  .dashboard {
    grid-template-columns: 1fr;
    margin-bottom: 4.5rem;
    height: auto;
  }
  .navbar {
    position: fixed;
    display: flex;
    justify-content: center;
    bottom: 0%;
    width: 100%;
    overflow: hidden;
    height: auto;
    z-index: 9;
    background: #1f1a43;
    padding: 0.5rem 0rem;
  }
  .navbar-container .logo {
    display: none;
  }
  .navbar-container {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
  }
  .navbar-link {
    padding: 1rem !important;
    margin: 0rem !important;
  }
  .navbar-link span {
    display: none;
  }
  .navbar-link img {
    margin: 0px !important;
  }
  .header {
    flex-direction: column;
  }
  .header-title,
  .header-actions {
    text-align: center;
    width: 100%;
  }
  .header-title {
    margin-bottom: 0.5rem;
  }
  .header-search {
    width: 100%;
  }
  .youtube-content {
    display: block;
    grid-template-columns: 1fr;
    padding: 2rem;
  }
  .youtube-content-card-1,
  .youtube-content-card-2,
  .youtube-content-card-3,
  .youtube-content-card-4,
  .youtube-content-card-5,
  .youtube-content-card-6,
  .youtube-content-card-7,
  .youtube-content-card-8 {
    margin: 0.5rem 0rem;
  }
  .thumbnail {
    grid-template-columns: 1fr 2fr;
  }
  .thumbnail-title {
    color: var(--clrPrimaryDark);
    font-weight: 600;
    font-size: 16px;
    max-width: initial;
    white-space: initial;
    text-overflow: initial;
    overflow: initial;
  }
}
.privacy-heading-1 {
  font-size: 5rem;
}
.privacy-heading-1 > strong {
  font-weight: 700;
}
.privacy-paragraph {
  margin-bottom: 3rem;
  font-size: large;
  font-weight: 300;
}
.privacy-wrapper {
  padding: 6rem 12rem;
}
.privacy-wrapper {
  color: var(--clrPrimaryDark);
}

@media screen and (max-width: 768px) {
  .header {
    padding: 1rem;
  }
  .youtube-header {
    display: flex;
    flex-direction: column;
  }
  .youtube-content {
    padding: 1rem;
  }
}
@media screen and (max-width: 950px) {
  .date-range-filter {
    min-width: fit-content;
    right: -110%;
  }
}
